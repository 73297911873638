const baseUrl = `${process.env.VUE_APP_API_URL}`;

export default {
    auth: `${baseUrl}user/authenticate/`,
    profile: `${baseUrl}user/profile/`,
    updateProfile: `${baseUrl}user/profile/update/`,
    getLang: `${baseUrl}user/get/lang/`,
    setLang: `${baseUrl}user/set/lang/`,
    need: `${baseUrl}need/search/`,
    needSave: `${baseUrl}need/update/`,
    needSaveDraft: `${baseUrl}need/draft/save/`,
    needDrafts: `${baseUrl}need/draft/search/`, //GET, jos haetaan tietty oma luonnos, niin id tämän perään
    deleteNeed: `${baseUrl}need/delete/`, // Delete also drafts
    keywords: `${baseUrl}keyword/search/`,
    locations: `${baseUrl}location/search/`,
    viewed: `${baseUrl}need/viewed/`,
    liked: `${baseUrl}need/liked/`,
    comments: `${baseUrl}need/comments/search/`, //get comments
    comment: `${baseUrl}need/comments/insert/`, //new comment
    commentResponse: `${baseUrl}need/comments/response/`, //response to existing comment
    userRegistration: `${baseUrl}user/registration/`, //POST
    userActivation: `${baseUrl}user/activation/`, //POST
    userChangePasswordLink: `${baseUrl}user/changepassword/`, //POST
    agreements: `${baseUrl}agreements/search/`, //GET
    agreementSign: `${baseUrl}agreements/sign/`, //GET
    agreement: `${baseUrl}agreement/`, //GET
    //admin
    adminUsers: `${baseUrl}admin/user/search/`, //GET
    adminUpdateUser: `${baseUrl}admin/user/update/`, //POST
    adminNeeds: `${baseUrl}admin/need/search/`, //POST
    adminNeedUpdate: `${baseUrl}admin/need/update/`, //POST
    adminComments: `${baseUrl}admin/comment/search/`, //POST
    adminUpdateComment: `${baseUrl}admin/comment/update/`, //POST
    adminDomains: `${baseUrl}admin/domains/search/`, //GET
    adminUpdateDomain: `${baseUrl}admin/domains/update/`, //POST
    adminCreateUser: `${baseUrl}admin/user/create/`, //POST
    adminEmailTemplates: `${baseUrl}admin/email-templates/search/`, //POST 
    adminAgreements: `${baseUrl}admin/agreements/search/`, //POST 
    adminSaveAgreement: `${baseUrl}admin/agreements/update/` //POST 
};