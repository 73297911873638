import { NeedDraft, NeedLocation } from "@/types/Need";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";
import { FieldError, SingleFieldErrorValue } from "@/types/Error";
import { Ref } from "vue";
import { useNeedStore } from "@/stores/need-store";

export const placeHolderIfEmpty = (content: string | null | undefined | number, placeHolder = '-', postFix = '') => {
    return content ? content + postFix : placeHolder;
};
//TODO sama nimi kuin date.ts:ssä
const formatDate = (isoDateString: string): string => {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
}
export const locationAndDateString = (location: NeedLocation | null | undefined, date: string) => {
    /*let loc = placeHolderIfEmpty(location?.clinic,'', ' ')+placeHolderIfEmpty(location?.department,'', ' ')
    +placeHolderIfEmpty(location?.ward,'', ' ')+placeHolderIfEmpty(location?.city,'', ' ');*/
    let loc = placeHolderIfEmpty(location?.clinic, '', ' ') + placeHolderIfEmpty(location?.department, '', ' ')
        + placeHolderIfEmpty(location?.city, '', ' ');
    if (loc.trim() !== '' && date) loc += ' - ';
    if (date) {
        const dateS = formatDate(date);
        loc += dateS;
    }
    return loc;
};

// A huge abomination to do a thing that should/could have been much simpler
// Used to transform drafts into Needs so they can be re-edited in NeedForm.vue
export const draftToNeed = async (id: string, need: Ref<NeedDraft | undefined>): Promise<NeedDraft | undefined> => {
    const needStore = useNeedStore();
    try {
        const needs = await needStore.fetchDraft(id);
        const draft =
            needs && needs.data && needs.data.needs
                ? needs.data.needs[0]
                : undefined;
        console.log("draft: ", draft)
        for (const key in draft) {
            if (Object.prototype.hasOwnProperty.call(need.value, key)) {
                if (typeof (draft as any)[key] == "string") {
                    //console.log("got to string key: ", key, " with value: ", (draft as any)[key]);
                    (need.value as any)[key] = (draft as any)[key];
                }
                else if (typeof (draft as any)[key] == "object") {
                    if ((draft as any)[key] !== undefined) {
                        //console.log("got into objects with key: ", key, " with value: ", (draft as any)[key]);
                        if (key === "location") {
                            if ((draft as any)[key].length > 0) {
                                // Take into account the fact that location is returned as array, only which the first index is "filled"
                                (need.value as any)[key] = (draft as any)[key][0];
                            }
                        }
                        else {
                            if ((draft as any)[key]) {
                                (need.value as any)[key] = (draft as any)[key];
                            }
                        }
                    }
                    else (need.value as any)[key] = ""
                }
            }
        }
        console.log("Need: ", need.value)
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const dateToISO = (formattedDateTimeString: string): string | null => {
    const match = formattedDateTimeString.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}).(\d{2})$/);
    if (match) {
        const [, year, month, day, hours, minutes, seconds] = match;
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    }
    return null;
};
export const split = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};
// eslint-disable-next-line
export const isStringArray = (array: any[]): boolean => {
    return array.filter((i) => typeof i === 'string').length > 0;
}
const singleErrorTranslation = (key: string, item: string): TranslateResult => {
    const keyValidation = 'validation.' + item;
    const translation = i18n.te(keyValidation) ? i18n.t(keyValidation, { field: key }) : i18n.t(item, { field: key });
    //const translation = i18n.t(errors[key][0], { field: key });
    return translation;
}
export const isRecordType = (item: unknown): boolean => {
    if (item && typeof item === 'object') return 'key' in item;
    return false;
}

export const errorsToMessage = (errors: FieldError) => { //Record<string, string[]> | null | undefined) => {
    if (!errors) return "";
    let s = "<ul>";
    Object.keys(errors).forEach(key => {
        //console.log('1', key, errors, typeof errors, errors[key]?.length);
        if (errors[key] !== undefined && errors[key] !== null && errors[key]?.length) {
            //console.log('1.1', key);
            //for (let i = 0; i < errors[key].length; i++) {
            //errors[key].forEach((item: string[] | string | Record<string, string[]> | null | undefined) => {
            errors[key].forEach((item: SingleFieldErrorValue) => {
                //console.log('2', key, item, typeof item, Array.isArray(item) && isStringArray(item), isRecordType(item));
                if (typeof item === 'string') {
                    //console.log('string', key, item);
                    s += "<li>" + singleErrorTranslation(key, item) + "</li>";
                } else if (Array.isArray(item)) {
                    //console.log('3', item);
                    item.forEach((i: string | FieldError) => {
                        if (typeof i === 'string') {
                            s += "<li>" + singleErrorTranslation(key, i) + "</li>" //etc. [ "VALIDATION_ERROR_ARRAYMINLENGTH" ]
                        } else {
                            //TODO translate parent key
                            s += "<li>" + key + errorsToMessage(i as FieldError) + "</li>";
                        }

                    });
                }
            });
        }
    });
    s += "</ul>";
    return s;
};
export const hasErrors = (errors: Record<string, string[]> | null | undefined) => {
    if (!errors) return false;
    let singleError = false;
    Object.keys(errors).forEach(key => {
        if (errors[key].length !== 0) singleError = true;
    });
    return singleError;
};
export const isDomain = (value: string) => {
    const regexp = new RegExp('^[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,6}$', 'i');
    return regexp.test(value);
};
export const isUrl = (value: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(value);
}