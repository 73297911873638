import { defineStore } from 'pinia';

import { fetchWrapper, RequestPayload } from '../endpoints/request';
//import router  from '../router';
import { User, IUserState, UserLoginResponse, UserLangResponse, ProfileResponse, Profile } from '../types/User';
import paths from '../router/paths';
import router from '@/router';
import { StandardModelUpdateResponse } from '@/types/Need';
import { AgreementResponse } from '@/types/Templates';
import global from '@/composables/global';

const initializeUser = (): User | null => {
    const userS = localStorage.getItem('user');
    if (userS !== null) {
        const user: User = JSON.parse(userS);
        return user;
    }
    return null;
};

export const useAuthStore = defineStore({
    id: 'auth',
    state: (): IUserState => ({
        // initialize state from local storage to enable user to stay logged in
        user: initializeUser(),
        returnUrl: null
    }),
    actions: {
        async authenticate(username: string, password: string): Promise<UserLoginResponse | null> {
            const response = await fetchWrapper.post(`${paths.auth}`, { email: username, password: password }) as UserLoginResponse | null;
            return response;
        },
        getUserName(): string {
            if (this.user) {
                const firstName = this.user.firstName ? this.user.firstName : '';
                const lastName = this.user.lastName ? this.user.lastName : '';
                return firstName && lastName ? (firstName + ' ' + lastName) : this.user.username ? this.user.username : '';
            }
            return '-';
        },
        async userRegister(payload: RequestPayload) {
            const response = await fetchWrapper.post(`${paths.userRegistration}`, payload) as StandardModelUpdateResponse | null;
            return response;
        },
        async userActivation(payload: RequestPayload) {
            const response = await fetchWrapper.post(`${paths.userActivation}`, payload) as StandardModelUpdateResponse | null;
            return response;
        },
        async userChangePassword(payload: RequestPayload) {
            const response = await fetchWrapper.post(`${paths.userChangePasswordLink}`, payload) as StandardModelUpdateResponse | null;
            return response;
        },
        getMyEmail(): string | undefined | null {
            return this.user?.email;
        },
        isAdmin(): boolean {
            if (this.user) {
                return this.user && this.user.type && this.user.type >= 3 ? true : false;
            }
            return false;
        },
        setUser(email: string | null, username: string | null, token: string | null | undefined, firstName: string | null | undefined, lastName: string | null | undefined, type: number | null | undefined) {
            this.user = {
                token: token,
                email: email,
                username: username,
                firstName: firstName,
                lastName: lastName,
                type: type
            };
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        async fetchProfile(): Promise<ProfileResponse | null> {
            const response = await fetchWrapper.get(`${paths.profile}`) as ProfileResponse | null;
            return response;
        },
        async saveProfile(profile: Profile): Promise<ProfileResponse | null> {
            const response = await fetchWrapper.post(`${paths.updateProfile}`, profile) as ProfileResponse | null;
            return response;
        },
        isLoggedIn() {
            return this.user?.token ? true : false;
        },
        logout() {
            this.user = null;
            //const router = useRouter(); 
            localStorage.removeItem('user');
            router.push('/login').catch(() => { console.log('redirect login error') });
        },
        async fetchUnsignedAgreements() {
            const response = await fetchWrapper.get(`${paths.agreements}`) as AgreementResponse | null;
            return response;
        },
        async checkAgreements() {
            await this.fetchUnsignedAgreements().then((response: AgreementResponse | null) => {
                if (response?.status === 1) {
                    if (response.data?.agreements && response.data?.agreements.length > 0) {
                        global().setUnsignedAgreements(response.data?.agreements);
                    } else {
                        global().setUnsignedAgreements([]);
                    }
                }
            });
        },
        async signAgreement(id: string) {
            const response = await fetchWrapper.get(`${paths.agreementSign}${id}`) as StandardModelUpdateResponse | null | undefined;
            return response;
        },
        async switchLanguage(lang: string): Promise<UserLangResponse | null> {
            const response = await fetchWrapper.post(`${paths.setLang}`, { lang: lang }) as UserLangResponse | null;
            if (response?.status && response.status === 1) {
                this.storeLang(lang);
            }
            return response;
        },
        async getLanguage(): Promise<UserLangResponse | null> {
            const response = await fetchWrapper.get(`${paths.getLang}`) as UserLangResponse | null;
            return response;
        },
        storeLang(lang: string | undefined = undefined): string | null {
            if (lang) {
                localStorage.setItem('bio-design-lang', lang);
                return lang;
            } else {
                return localStorage.getItem('bio-design-lang');
            }
        }

        //watch() => localstorage? 
    }
});