//import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
//import { i18n } from './locales'
import { useBootstrap } from './plugins/bootstrap';
import '@/assets/styles/main.scss'
import i18n from './i18n'
import pinia from './stores';
import router from './router'
import './prime'
import './vee-validate';
//const fi = require('./locales/fi.json');
//const en = require('./locales/en.json');

/*const app = createApp(App)
app.use(i18n);

app.use(router).mount('#app')*/
Vue.config.productionTip = false;
//const router = useRouter(); 
const vue = new Vue({
    render: h => h(App),
    router,
    i18n,
    pinia,
});
//bindBeforeEach(router);
useBootstrap();
vue.$mount('#app')
