import { reactive, toRefs } from 'vue'
import { Agreement } from '@/types/Templates'

interface StateObject {
    unsignedAgreements: Agreement[] | undefined | null;
}
const state = reactive<StateObject>({
    unsignedAgreements: []
})

export default () => {

    const setUnsignedAgreements = (agreements: Agreement[] | undefined | null) => {
        state.unsignedAgreements = agreements
    }

    return {
        ...toRefs(state),
        setUnsignedAgreements
    }
}