
import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye, faExclamation, faPlus, faComment, faMinus, faUser, faReply, faHeart, faBars, faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faGear, faList, faBook, faCheck, faCopyright } from '@fortawesome/free-solid-svg-icons'
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'



export const useBootstrap = () => {
    /* add icons to the library */
    library.add(faPlus);
    library.add(faMinus);
    library.add(faUser);
    library.add(faReply);
    library.add(faHeart);
    library.add(faBars);
    library.add(faChevronUp);
    library.add(faChevronDown);
    library.add(faChevronRight);
    library.add(faChevronLeft);
    library.add(faCopyright);
    library.add(faGear);
    library.add(faList);
    library.add(faComment);
    library.add(faBook);
    library.add(faCheck);
    library.add(faExclamation);
    library.add(faEye);
    /* add font awesome icon component */
    Vue.component('font-awesome-icon', FontAwesomeIcon)
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
}