import { defineStore } from 'pinia';

import { fetchWrapper } from '../endpoints/request';
import paths from '../router/paths';
import { INeedState, NeedResponse, Need, ImageResponse, NeedUpdateResponse, StandardResponse, LocationResponse, NeedDraft, NeedDraftResponse, NeedDraftUpdateResponse } from '@/types/Need';
import i18n from '@/i18n';

export const useNeedStore = defineStore({
    id: 'needs',
    state: (): INeedState => ({
        // initialize state from local storage to enable user to stay logged in
        needs: null,
    }),
    actions: {
        async fetchNeeds(type = '', searchText = '', page = 1, number = 10): Promise<NeedResponse | null> {
            const response = await fetchWrapper.post(`${paths.need}`, { filter: type, searchText: searchText, page: page, number: number, preferredLang: i18n.locale }) as NeedResponse | null;
            return response;
        },
        async fetchNeed(id: string): Promise<NeedResponse | null> {
            const response = await fetchWrapper.post(`${paths.need}`, { id: id }) as NeedResponse | null;
            return response;
        },
        async fetchImage(url: string): Promise<string | null | undefined> {
            if (url.trim() === '') {
                return require("@/assets/images/placeholder/question-mark.png");
            }
            const response = await fetchWrapper.get(`${url}`) as ImageResponse | null;
            return response?.data?.src;
        },
        async save(needObject: Need): Promise<NeedUpdateResponse | null> {
            const response = await fetchWrapper.post(`${paths.needSave}`, { need: needObject, lang: i18n.locale }) as NeedUpdateResponse | null;
            return response;
        },
        async fetchDrafts(): Promise<NeedDraftResponse | null> {
            const response = await fetchWrapper.get(`${paths.needDrafts}`) as NeedDraftResponse | null;
            return response;
        },
        async fetchDraft(id: string): Promise<NeedDraftResponse | null> {
            const response = await fetchWrapper.get(`${paths.needDrafts}${id}`) as NeedDraftResponse | null;
            return response;
        },
        async deleteNeed(needId: string): Promise<NeedDraftResponse | null> {
            const response = await fetchWrapper.post(`${paths.deleteNeed}` + needId) as NeedResponse | null;
            return response;
        },
        async saveDraft(needObject: NeedDraft): Promise<NeedDraftUpdateResponse | null> {
            const response = await fetchWrapper.post(`${paths.needSaveDraft}`, { need: needObject, lang: i18n.locale }) as NeedDraftUpdateResponse | null;
            return response;
        },
        async viewed(needId: string): Promise<StandardResponse | null> {
            const response = await fetchWrapper.get(`${paths.viewed}` + needId) as StandardResponse | null;
            return response;
        },
        async liked(needId: string): Promise<StandardResponse | null> {
            const response = await fetchWrapper.get(`${paths.liked}` + needId) as StandardResponse | null;
            return response;
        },
        async fetchLocations(field: string, searchText = ''): Promise<LocationResponse | null> {
            const response = await fetchWrapper.post(`${paths.locations}`, { field: field, searchText: searchText, preferredLang: i18n.locale }) as LocationResponse | null;
            return response;
        },
    }
});