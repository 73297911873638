import { useAuthStore } from '@/stores/auth-store';
//import router from '@/router';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};
interface RequestOptions {
    method: string;
    headers: Headers;
    body?: string;
}
export type RequestPayload = Record<string, unknown> | string | undefined;
//TODO nämä tyypit T pitäisi saada oikein
function request<T>(method: string) {
    return (url: string, body: RequestPayload = undefined): Promise<T> => {
        const requestOptions: RequestOptions = {
            method,
            headers: authHeader(url),
        };
        if (body) {
            //requestOptions.headers.set('Content-Type', 'application/json');
            //requestOptions.body = JSON.stringify(body);
            requestOptions.headers.set('Content-Type', 'text/plain');
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then<T>(handleResponse);
    }
}

// helper functions
function authHeader(url: string): Headers {
    // return auth header with jwt if user is logged in and request is to the api url
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.token;
    const apiUrl = process.env.VUE_APP_API_URL;
    const isApiUrl = url.startsWith(apiUrl);
    /*let initHeaders = {
        "Access-Control-Allow-Origin":  apiUrl,
        "Access-Control-Allow-Methods": "POST"
    Access-Control-Allow-Headers: Content-Type, Authorization*/
    let headers = new Headers();
    if (isLoggedIn && isApiUrl) {
        headers = new Headers({ Authorization: `Bearer ${user.token}` });
    }
    //headers.set()
    return headers;
}
function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function handleResponse<T>(response: Response): Promise<T> {
    return response.text().then<T>(text => {
        try {
            const data = text && isJsonString(text) ? JSON.parse(text) : {};
            //console.log(response);
            if (!response.ok) {
                const { logout } = useAuthStore();
                //TODO täytyy testata tämä
                if ([401, 403].includes(response.status)) {
                    //console.log(response.status, [401, 403].includes(response.status));
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    logout();
                    //router.push('/login');
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        } catch (e) {
            return Promise.reject(e);
        }
    });
}